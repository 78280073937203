import {
  NOTIFICATION_GET_LIST,
  NOTIFICATION_GET_LIST_ERROR,
  NOTIFICATION_GET_LIST_SUCCESS,
} from '../contants';

const INIT_STATE = {
  allNotifications: [],
  error: null,
  loaded: false,
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_GET_LIST:
      return { ...state, loaded: false };

    case NOTIFICATION_GET_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        allNotifications: action.payload,
        error: null,
      };

    case NOTIFICATION_GET_LIST_ERROR:
      return {
        ...state,
        loaded: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
