// socket.js
import io from 'socket.io-client';
import { SOCKET_URL } from 'constants/defaultValues';

let socket;

export const InitializeSocket = (token, uid, role) => {
  if (!socket) {
    socket = io(SOCKET_URL, {
      query: { userType: role, userId: uid, userToken: token },
      extraHeaders: { authorization: token },
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
    });

    socket.on('disconnect', () => {
      console.warn('Socket disconnected, attempting to reconnect...');
    });

    // socket.on('receive_message', (data) => {
    //   console.log('receive_message', data);
    //   alert('messa');
    // });
    socket.on('reconnect', () => {
      console.log('Socket reconnected');
    });

    socket.on('socket_error', (message) => {
      console.error(`Socket Error: ${message.error}`);
    });
  }
  return socket;
};

export const getSocket = () => socket;
