import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import axios from 'axios';
import { servicePath } from 'constants/defaultValues';
import { getSocket, InitializeSocket } from 'hooks/useSocket';
import { eventChannel } from 'redux-saga';
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONVERSATIONS,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_GET_MESSAGES,
  CHAT_CHANGE_CONVERSATION,
  GET_UNN_SEE_CONVERSATION,
} from '../contants';
import {
  getContactsSuccess,
  getContactsError,
  getConversationsSuccess,
  getConversationsError,
  getMessagesSuccess,
  getMessagesError,
  updateUnsennConversation,
  seeConversation,
  reloadConv,
  changeConversation,
  socketUpdateConversation,
} from './actions';

const getAuthState = (state) => state.authUser.currentUser;
const getAllMessages = (state) => state.chatApp.conversationMessages;
const getAllConversation = (state) => state.chatApp.conversations;
const getCurrentConversation = (state) => state.chatApp.currentConversation;
const emitSocketEvent = (event, data) => {
  const socket = getSocket();
  console.log('Socket xx', socket);

  if (socket && socket.connected) {
    socket.emit(event, data);
  } else {
    console.error(`Failed to emit ${event} - socket not connected`);
  }
};

const createReceiveMessageChannel = (socket) =>
  eventChannel((emitter) => {
    socket.on('receive_message', (message) => {
      console.log('receive_message', message);
      emitter(message);
    });

    return () => {
      socket.off('receive_message'); // Detach the event to avoid memory leaks
    };
  });

function* handleReceiveMessage() {
  const currentUser = yield select(getAuthState);
  InitializeSocket(currentUser.token, currentUser.uid, currentUser.role);
  const socket = getSocket();

  if (!socket) {
    console.error('Socket xx connection not found', socket);
    return;
  }

  const receiveMessageChannel = yield call(createReceiveMessageChannel, socket);
  console.log('receiveMessageChannel', receiveMessageChannel);

  try {
    while (true) {
      const message = yield take(receiveMessageChannel);
      const allMessages = yield select(getAllMessages);
      const allConversations = yield select(getAllConversation);
      const currentConversation = yield select(getCurrentConversation);
      const updatedMessages = [message, ...allMessages];
      const unseenConv = allConversations.find(
        (x) => x.id === message.conversationId && !x.seen
      );
      console.log('socetEvent_unseenConv', unseenConv);
      console.log('socetEvent_currentConversation', currentConversation);
      console.log('socetEvent_updatedMessages', updatedMessages);
      if (
        currentConversation &&
        currentConversation.id === message.conversationId
      ) {
        yield put(getMessagesSuccess(updatedMessages));
        yield put(socketUpdateConversation(message, true));
      } else {
        yield put(socketUpdateConversation(message, false));
      }
      yield put(reloadConv());
    }
  } catch (error) {
    console.error('Error in handleReceiveMessage:', error);
  } finally {
    receiveMessageChannel.close();
  }
}

const loadContactsAsync = async (token) => {
  try {
    const result = await axios.post('https://wefarmers.me/api/Getalluser', {
      token,
    });
    return result.data.resultat;
  } catch (error) {
    console.log('Error fetching contacts:', error);
    return [];
  }
};
function* loadContacts() {
  try {
    const currentUser = yield select(getAuthState);
    const response = yield call(loadContactsAsync, currentUser.token);
    yield put(getContactsSuccess(response, currentUser));
  } catch (error) {
    yield put(getContactsError(error));
  }
}
const loadConversationsAsync = async (uid) => {
  try {
    const response = await axios.get(
      `${servicePath}/messages/allConversations/${uid}`
    );
    const result = response.data;
    console.log('thsi is all conv', result);
    if (result.length > 0) {
      const selectedUser =
        uid === result[0].user1id ? result[0].user1id : result[0].user2id;
      return { conversations: result, selectedUser };
    }
    return 'There are no conversations yet';
  } catch (error) {
    console.log('Error fetching conversations:', error);
    return error;
  }
};

function* loadConversations() {
  try {
    const { uid } = yield select(getAuthState);
    const response = yield call(loadConversationsAsync, uid);
    const { conversations, selectedUser } = response;

    if (conversations && selectedUser) {
      yield put(getConversationsSuccess(conversations, selectedUser));
    } else {
      yield put(getConversationsError(response));
    }
  } catch (error) {
    yield put(getConversationsError(error));
  }
}
function* loadUnnSeeConversations() {
  try {
    const { uid } = yield select(getAuthState);
    const response = yield call(loadConversationsAsync, uid);
    const { conversations } = response;
    const count = conversations.filter(
      (conv) => conv.seen === false && conv.recentuseraddid !== uid.toString()
    ).length;
    if (conversations) {
      yield put(updateUnsennConversation(count));
    }
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const addMessageToConversationAsync = async (
  conversationId,
  userId,
  userEmail,
  userName,
  messageContent,
  userImageLink,
  allMessages
) => {
  try {
    const response = await axios.post(`${servicePath}/messages/addMessage`, {
      conversationId,
      userId,
      userEmail,
      userName,
      messageContent,
      userImageLink,
    });
    const newMessage = response.data?.messageCreated;

    if (!newMessage) {
      throw new Error('Failed to create a new message');
    }
    return [newMessage, ...allMessages];
  } catch (error) {
    return { error: 'Could not create this message', details: error };
  }
};
function* addMessageToConversation({ payload }) {
  try {
    const {
      conversationId,
      userId,
      userEmail,
      userName,
      messageContent,
      userImageLink,
      allMessages,
      receiverId,
    } = payload;

    emitSocketEvent('send_message', {
      conversationId,
      userid: userId,
      useremail: userEmail,
      username: userName,
      messagecontent: messageContent,
      userimage: userImageLink,
      receiverId,
    });

    const response = yield call(
      addMessageToConversationAsync,
      conversationId,
      userId,
      userEmail,
      userName,
      messageContent,
      userImageLink,
      allMessages
    );

    yield put(getMessagesSuccess(response));
  } catch (error) {
    yield put(getMessagesError(error));
  }
}

const createNewConversationAsync = async (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  try {
    const response = await axios.post(`${servicePath}/messages/add`, {
      user1Id: currentUserId.toString(),
      user2Id: selectedUserId.toString(),
    });

    const newConversation = response.data?.conversation;

    if (!newConversation) {
      throw new Error('Failed to create a new conversation');
    }
    return {
      conversations: [newConversation, ...allConversations],
      selectedUser: selectedUserId,
      newConversation,
    };
  } catch (error) {
    console.error('Error adding conversation:', error);
    return { error: 'Could not create conversation', details: error };
  }
};

function* createNewConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, allConversations } = payload;
    const response = yield call(
      createNewConversationAsync,
      currentUserId,
      selectedUserId,
      allConversations
    );
    yield put(
      getConversationsSuccess(response.conversations, response.selectedUser)
    );
    yield put(changeConversation(selectedUserId, response.newConversation));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}
const loadMessagesAsync = async (conversationId) => {
  try {
    const result = await axios.get(`${servicePath}/messages/${conversationId}`);
    return result.data;
  } catch (error) {
    throw new Error(error);
  }
};

function* loadMessages({ payload }) {
  try {
    const response = yield call(loadMessagesAsync, payload);
    yield put(getMessagesSuccess(response));
  } catch (error) {
    yield put(getMessagesError(error));
  }
}
const markConversationAsSeenAsync = async (conversationId) => {
  try {
    await axios.patch(
      `${servicePath}/messages/seeConversation/${conversationId}`
    );
    console.log('api of see');
  } catch (error) {
    console.error('Failed to mark conversation as seen:', error);
  }
};
function* markConversationAsSeen({ payload }) {
  try {
    const { userId, conversation } = payload;
    const currentUser = yield select(getAuthState); // Select the current user state correctly
    const { uid } = currentUser;

    if (conversation && userId) {
      // Only mark as seen if the recent user who added the message is not the current user
      if (conversation.recentuseraddid !== uid.toString()) {
        yield put(seeConversation(conversation.id)); // Update the Redux state
        yield call(markConversationAsSeenAsync, conversation.id); // Call API to mark as seen
      }
    }
  } catch (error) {
    console.error('Failed to mark conversation as seen:', error);
  }
}

export function* watchGetContact() {
  yield takeEvery(CHAT_GET_CONTACTS, loadContacts);
}

export function* watchGetConversation() {
  yield takeEvery(CHAT_GET_CONVERSATIONS, loadConversations);
}

export function* watchGetMessages() {
  yield takeEvery(CHAT_GET_MESSAGES, loadMessages);
}

export function* watchAddMessageToConversation() {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation);
}

export function* watchCreateConversation() {
  yield takeEvery(CHAT_CREATE_CONVERSATION, createNewConversation);
}
export function* watchSeeConversation() {
  yield takeEvery(CHAT_CHANGE_CONVERSATION, markConversationAsSeen);
}
export function* watchUnnSeeConversation() {
  yield takeEvery(GET_UNN_SEE_CONVERSATION, loadUnnSeeConversations);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetContact),
    fork(watchGetConversation),
    fork(watchAddMessageToConversation),
    fork(watchCreateConversation),
    fork(watchGetMessages),
    fork(handleReceiveMessage),
    fork(watchSeeConversation),
    fork(watchUnnSeeConversation),
  ]);
}
