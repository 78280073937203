import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
  CHAT_GET_MESSAGES,
  CHAT_GET_MESSAGES_SUCCESS,
  CHAT_GET_MESSAGES_ERROR,
  UPDATE_UNSEEN_CONVERSATION_COUNT,
  MARK_CONVERSATION_AS_SEEN,
  GET_UNN_SEE_CONVERSATION,
  SOCKET_UPDATE_CONVERSATION,
} from '../contants';

export const getContacts = () => ({
  type: CHAT_GET_CONTACTS,
});

export const getContactsSuccess = (contacts, currentUser) => {
  return {
    type: CHAT_GET_CONTACTS_SUCCESS,
    payload: { contacts, currentUser },
  };
};

export const getContactsError = (error) => ({
  type: CHAT_GET_CONTACTS_ERROR,
  payload: error,
});

export const getConversations = (userId) => {
  console.log('*****************first*****');
  return {
    type: CHAT_GET_CONVERSATIONS,
    payload: userId,
  };
};
export const getConversationsSuccess = (conversations, selectedUser) => ({
  type: CHAT_GET_CONVERSATIONS_SUCCESS,
  payload: { conversations, selectedUser },
});

export const getConversationsError = (error) => ({
  type: CHAT_GET_CONVERSATIONS_ERROR,
  payload: error,
});

export const addMessageToConversation = (
  conversationId,
  userId,
  userEmail,
  userName,
  messageContent,
  userImageLink,
  allMessages,
  receiverId
) => ({
  type: CHAT_ADD_MESSAGE_TO_CONVERSATION,
  payload: {
    conversationId,
    userId,
    userEmail,
    userName,
    messageContent,
    userImageLink,
    allMessages,
    receiverId,
  },
});

export const createConversation = (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  return {
    type: CHAT_CREATE_CONVERSATION,
    payload: { currentUserId, selectedUserId, allConversations },
  };
};

export const searchContact = (keyword) => {
  console.log('===============>');
  return {
    type: CHAT_SEARCH_CONTACT,
    payload: keyword,
  };
};

export const changeConversation = (userId, conversation) => {
  console.log('changeC_userId', userId);
  console.log('changeC_conversation', conversation);
  return {
    type: CHAT_CHANGE_CONVERSATION,
    payload: { userId, conversation },
  };
};

// get messages actions
export const getMessages = (conversationId) => {
  console.log('getMessageac');
  return {
    type: CHAT_GET_MESSAGES,
    payload: conversationId,
  };
};

export const getMessagesSuccess = (Messages) => {
  console.log('getMessageacsssssssssss');
  return {
    type: CHAT_GET_MESSAGES_SUCCESS,
    payload: Messages,
  };
};

export const getMessagesError = (error) => ({
  type: CHAT_GET_MESSAGES_ERROR,
  payload: error,
});
export const updateUnsennConversation = (unseenCount) => {
  console.log('actions_message_notif', unseenCount);
  return {
    type: UPDATE_UNSEEN_CONVERSATION_COUNT,
    payload: unseenCount,
  };
};

export const seeConversation = (conversationId) => ({
  type: MARK_CONVERSATION_AS_SEEN,
  payload: conversationId,
});
export const loadUnnSeeConversations = () => ({
  type: GET_UNN_SEE_CONVERSATION,
});

export const reloadConv = () => {
  console.log('RELOADE_CONVERSATION');
  return {
    type: 'RELOADE_CONVERSATION',
  };
};
export const socketUpdateConversation = (message, updateSeenStatus = true) => ({
  type: SOCKET_UPDATE_CONVERSATION,
  payload: { message, updateSeenStatus },
});
